import Chart from "chart.js";
import { Doughnut, mixins } from "vue-chartjs";

//Chart.defaults.global.legend.position = "right";

export default {
  extends: Doughnut,
  props: ["chartData", "options"],
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options);
  }
};
