<template>
<div class="etf-backtest-result"> 
  <div v-if="testShow">
    <div class="d-flex justify-start mb-6 text-h6">
      <v-icon left @click="$router.go(-1)"> mdi-arrow-left </v-icon> 
      백테스트 결과
    </div>

    <!-- 상단 버튼 -->
    <v-container fluid>
      <v-row
        no-gutters
      >
        <v-col  
          v-for="j in rows"
          :key="j"
          :class="rows === 1 ? 'd-flex justify-end' : 'd-flex justify-space-between'"
        >
          <v-btn     
            v-for="k in 2/rows"
            :key="k"
            v-show="btnShow[j*k-1]"
            text
            color="primary"
            small 
            class="font-weight-bold"
            @click="btnFnc(j*k-1)"
          >
            <v-icon left> 
              {{ icon[j*k-1] }} 
            </v-icon>
            {{ btnName[j*k-1] }}
          </v-btn>        
        </v-col>
      </v-row>
    </v-container>


    <!-- 나의 전략에 추가 Dialog-->
    <v-dialog 
      v-model="ruleDialogShow"            
      width="600" 
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
    <v-card>
      <v-card-title>
      <span class="text-h5">나의 전략에 추가</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">               
              <v-text-field
                  v-model="myRuleTitle"
                  label="제목*"
                  required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">               
              <v-textarea
                  v-model="myRuleDescription"
                  label="매수/매도 전략"
                  hint="자신의 자산배분전략을 입력하세요."
                  persistent-hint
                  outlined
                  auto-grow
              ></v-textarea>
            </v-col>
          </v-row>          
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end pa-6">
          <v-btn depressed outlined @click="ruleDialogShow = false"> 닫 기 </v-btn>
          <v-btn color="primary" @click="saveMyRule" > 저 장 </v-btn>
        </v-card-actions>
      </v-card>      
    </v-dialog>
    
    <!-- 도움말 다이알로그-->
    <v-dialog
      v-model="helpDialogShow"
    >
      <v-card>
        <v-card-title class="text-h6 grey lighten-2">
          {{ helpTitle }}
        </v-card-title>
        <v-card-text>
          <br>
          <p class="text-left"> {{ helpText }} </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="helpDialogShow = false"
          >
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="text-h6" align="center"> {{ modelTitle }}</div>
    <span class="text-subtitle-2">{{ invest_term }}</span>
    <br>

    <!-- Pie Chart -->
    <br>
    <div class="text-h6" align="start"> 자산 배분</div>
    <br>  
    <v-container>
      <v-row>
        <v-col>
          <v-data-table
            :headers="allocate_headers"
            :items="allocate_lists"
            :items-per-page="100"
            class="elevation-1"
            :hide-default-footer=true
            :loading="loadTable"
            mobile-breakpoint="0"
            loading-text="백테스트 중입니다. 잠시만 기다려 주세요."
          ></v-data-table>
        </v-col>
        <v-col>      
          <v-card v-if = "overviewShow">
            <template>
              <div class="container">
                <pie-chart
                    v-if="pie_chartLoaded"
                    :chartData="pie_datacollection"
                    :options="pie_chart_options"
                    :height="chart_hight" 
                />
              </div> 
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

  <!-- 백테스트 통계 -->
    <br>
    <div class="text-h6" align="start"> 백테스트 통계 </div>
    <br>
    <v-card>
    <template>
      <v-simple-table v-if = "overviewShow">
        <template v-slot:default>
          <thead>
            <tr> 
              <th class="grey darken-1 text-left subtitle-2"><span class="white--text"> 통계지표 </span></th> 
              <th class="grey darken-1 text-right subtitle-2" ><span class="white--text"> 결과값 </span></th>
              <th class="grey darken-1 text-left subtitle-2"><span class="white--text"> 통계지표 </span></th> 
              <th class="grey darken-1 text-right subtitle-2"><span class="white--text"> 결과값 </span></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-left"> 초기투자금(USD) </td>
              <td class="text-right">{{ backtestResult.invest_amount.toLocaleString() }}</td>
              <td class="text-left"> 최종자산(USD) </td>
              <td class="text-right">{{ Number(backtestResult.last_estimate_amount).toLocaleString() }}</td>
            </tr>
            <tr>              
              <td class="text-left"> 연복리수익율(%) </td>
              <td class="text-right">{{ backtestResult.annualized_return }}</td>
              <td class="text-left"> 월간 변동성(표준편차) </td>
              <td class="text-right">{{ backtestResult.stdev }}</td>
            </tr>
            <tr>
              <td class="text-left"> MDD(%) </td>
              <td class="text-right">{{ backtestResult.mdd }} <br> 
                <span class="caption">({{ backtestResult.mdd_month }})</span>
              </td> 
              <td class="text-left"> 최장 연속 손실개월수 </td>
              <td class="text-right">{{ backtestResult.longest_drawdown }}</td>
            </tr>
            <tr>
              <td class="text-left"> 승율(상승개월수/거래개월수)(%) </td>
              <td class="text-right">{{ backtestResult.profitable_months_ratio }}</td>
              <td class="text-left"> 손익비율(수익월/손실월) </td>
              <td class="text-right">{{ backtestResult.gain_loss_ratio }}</td>
            </tr>
            <tr>
              <td class="text-left"> 최대 연수익율(%) </td>
              <td class="text-right">{{ backtestResult.best_month_return }}</td>
              <td class="text-left"> 최소 연수익율(%) </td>
              <td class="text-right">{{ backtestResult.worst_month_return }}</td>
            </tr>
            <tr>
              <td class="text-left"> 샤프지수 </td>
              <td class="text-right">{{ backtestResult.sharpe_ratio }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
    </v-card>

    <!-- 자산 차트 -->
    <br><br>
    <div class="text-h6" align="start"> 누적 자산 차트 </div>
    <br>
    <v-card v-if = "overviewShow">
      <template>
        <div class="container">
        <line-chart
          v-if="chartLoaded"
          :chartData="datacollection"
          :options="chart_options"
          :height="200" 
        />        
        </div>
      </template>
    </v-card>

    <!-- MDD Chart -->
    <br><br>
    <div class="text-h6" align="start"> MDD 차트 </div>
    <br>    
    <v-card v-if = "overviewShow">
      <template>
          <div class="container">
              <line-chart
                v-if="mdd_chartLoaded"
                :chartData="mdd_datacollection"
                :options="mdd_chart_options"
                :height="200" 
              />
          </div> 
      </template>
    </v-card>

    <!-- Year Yield Bar Chart -->
    <br><br>
    <div class="text-h6" align="start"> 연간 수익율 차트 </div>
    <br>    
    <v-card v-if = "overviewShow">
      <template>
          <div class="container">
            <bar-chart
              v-if="bar_chartLoaded"
              :chartData="bar_datacollection"
              :options="bar_chart_options"
              :height="200" 
            />
          </div> 
      </template>
    </v-card>

    <br><br>
    <div class="text-h6" align="start"> 월간 & 년간 수익율 </div>
    <br>
    <v-card>
      <v-data-table
        :headers="month_yield_headers"
        :items="month_yield_lists"
        :items-per-page="100"
        class="elevation-1"
        :hide-default-footer=true
        :loading="loadTable"
        loading-text="백테스트 중입니다. 잠시만 기다려 주세요."
      ></v-data-table>    
    </v-card>
    <br>
    <v-subheader>
      (*) 년간 누적수익율은 연복리수익율로 1월부터 12월까지 단순 합산이 아닙니다. 계산식: [(1월% + 1) * (2월% + 1) * (3월% + 1) ... (12월% + 1)] - 1      
    </v-subheader>

    <br><br>
    <div class="text-h6" align="start"> 월별 자산 평가금 현황 </div>  
    <br>  
    <div class="text-caption" align="start"> {{ remark }} </div>
    <br>
    <v-card>
      <v-data-table
        :headers="balances_headers"
        :items="balances"
        :items-per-page="12"
        class="elevation-1"
        :loading="loadTable"
      ></v-data-table>    
    </v-card>

    <br>
    </div>
  
      <!-- 알림 메세지 -->
  <v-snackbar
    v-model="snackbar"
    :vertical="vertical"
    :color="snackColor"          
  >
    {{ snackText }}

    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
    
</div>
</template>

<script>
import axios from 'axios';
import {mapState, mapActions, mapMutations} from 'vuex';
import LineChart from '../components/LineChart.js';
import PieChart from '../components/PieChart.js';
import BarChart from '../components/BarChart.js';

export default {
    name: "EtfBacktestResult",
    components: { LineChart, PieChart, BarChart },

    data() {
        return {
          btnName: [
            '상세 퀀트통계 보기',
            '나의 전략에 추가',
          ],
          icon: [
            'mdi-numeric',
            'mdi-plus',
          ],

          btnShow: [true, true],
          testShow: false,
          tableShow: false,
          loadTable: false,     
          loadedChart: false, 
          ruleDialogShow: false,
          addBtnShow: false,
          overviewShow: false,
          helpDialogShow: false,

          myRuleTitle: null,
          myRuleDescription: null,

          qs_month: null,
          qs_data: null,
          invest_term: null,
          modelTitle: null,
          remark: null,
          helpTitle: null,
          helpText: null,
          backtestResult: null,
          cum_yield: null,
          avg_yield: null,
          yield_plus: null,
          std: null,
          mdd: null,
          sharpe: null,
          allocate_headers: [
            { text: '자산', value: 'name', align: 'start' },
            { text: '평균 배분율', value: 'ratio', align: 'end', sortable: false },
          ],
          allocate_lists: [],
          month_yield_headers: [
            { text: '년도', value: 'year', align: 'center', 'class': 'blue lighten-5' },
            { text: '1월', value: '01', align: 'end', sortable: false, 'class': 'blue lighten-5' },
            { text: '2월', value: '02', align: 'end', sortable: false, 'class': 'blue lighten-5' },
            { text: '3월', value: '03', align: 'end', sortable: false, 'class': 'blue lighten-5' },
            { text: '4월', value: '04', align: 'end', sortable: false, 'class': 'blue lighten-5' },
            { text: '5월', value: '05', align: 'end', sortable: false, 'class': 'blue lighten-5' },
            { text: '6월', value: '06', align: 'end', sortable: false, 'class': 'blue lighten-5' },
            { text: '7월', value: '07', align: 'end', sortable: false, 'class': 'blue lighten-5' },
            { text: '8월', value: '08', align: 'end', sortable: false, 'class': 'blue lighten-5' },
            { text: '9월', value: '09', align: 'end', sortable: false, 'class': 'blue lighten-5' },
            { text: '10월', value: '10', align: 'end', sortable: false, 'class': 'blue lighten-5' },
            { text: '11월', value: '11', align: 'end', sortable: false, 'class': 'blue lighten-5' },
            { text: '12월', value: '12', align: 'end', sortable: false, 'class': 'blue lighten-5' },
            { text: '합계', value: 'total', align: 'end', sortable: false, 'class': 'blue lighten-5' },
          ],
          balances_headers: [],
          balances: [],
          month_yield_lists: [],
          month_mdd_lists: [],
          datacollection: null,
          chartLoaded: null,
          mdd_datacollection: null,
          pie_datacollection: null,
          bar_datacollection: null,
          mdd_chartLoaded: null,
          pie_chartLoaded: null,
          bar_chartLoaded: null,
          
          chart_hight: 40,
          
          chart_options: {
            title: {
              display: false,
              text: '누적자산 차트',
            },
            scales: {
              xAxes: [{
                  gridLines: { drawOnChartArea: false }
              }],
              yAxes: [{
                  gridLines: { drawOnChartArea: true }
              }]
            },
            legend: {
              display: false,
            },
            tooltips: {
              axis: 'xy', 
              mode: 'nearest',
              intersect: false,
            },
          },
          mdd_chart_options: {
            title: {
              display: false,
              text: 'MDD',
            },
            scales: {
              xAxes: [{
                  gridLines: { drawOnChartArea: false }
              }],
              yAxes: [{
                  gridLines: { drawOnChartArea: true }
              }]
            },            
            legend: {
              display: false,
            },
            tooltips: {
              axis: 'xy', 
              mode: 'nearest',
              intersect: false
            },
          },
          pie_chart_options: {
            legend: {
              display: true,
              position: 'right',
            },
            hoverBorderWidth: 20,
          },
          bar_chart_options: {
            scales: {
              xAxes: [{
                  gridLines: { drawOnChartArea: false }
              }],
              yAxes: [{
                  gridLines: { drawOnChartArea: true }
              }]
            },   
            legend: {
              display: false,
            },
            tooltips: {
              axis: 'xy', 
              mode: 'nearest',
              intersect: false
            },
          },

          snackbar: false,
          vertical: true,
          snackColor: null,
          snackText: null,
      }
    },
    
    created() {
      if (this.isLogin) this.backtest();
      else this.$router.push("/");
    },
    
    computed: {
      ...mapState(['debug', 'isLogin', 'user', 'who', 'model_type', 'ports', 'amount', 'term', 'title']),
      
      rows() {
        const { xs } = this.$vuetify.breakpoint
        return xs ? 2 : 1
      },  
    },

    methods: {      
        ...mapMutations(['setTestTerm', 'setQS']),

        btnFnc(idx) {
          if ( idx === 0 ) this.showQS();
          else if ( idx === 1 ) this.addMyRule();
        },

        showQS() {
          this.$router.push("/show_qs")
        },          

        addMyRule() {
          if (this.ports.length != 1) {
            this.ruleDialogShow = false;
            this.snackbar = true;
            this.snackColor = 'warning';
            this.snackText = '단일 전략만 추가할 수 있습니다.';      
          } else {
            this.ruleDialogShow = true;
          }
        },

        saveMyRule() {
          var vm = this;    
          const req_data = { 'user': this.user, 'model_type': this.model_type, 'title': this.myRuleTitle,
            'description': this.myRuleDescription, 'ports': this.ports };   
          const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};  
          axios.post('/api/etf/port/my/save/', req_data, {headers})
            .then(function(res) {
              vm.ruleDialogShow = false;  
              vm.snackbar = true;
              vm.snackColor = 'success';
              vm.snackText = '저장되었습니다.';     
              vm.$router.push('/etf_port'); 
            })
            .catch(function (err) {
              vm.ruleDialogShow = false;  
              vm.snackbar = true;
              vm.snackColor = 'error';
              vm.snackText = err;      
            });  
        },
        
        backtest() {
            this.testShow = true;
            this.tableShow = true;  
            this.loadTable = true;     
            this.loadedChart = false;  
            var title = '';
            var _remark = ''
            this.ports.forEach(function(item){
              title += item['title']+' ';
              _remark += item['port_id']+': '+item['title']+' ';
            });
            this.modelTitle = title;
            this.remark = _remark;
            var backtestQuaryData = {}; 
            if (this.who === 'expert') this.addBtnShow = true;

            backtestQuaryData = { 'user': this.user, 'who': this.who, 'model_type': this.model_type,
              'ports': this.ports, 'amount': this.amount, 'term': this.term };
            
            var vm = this;    
                  
            const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};  
            axios.post('/api/etf/backtest/', backtestQuaryData, {headers})
              .then(function(res) {
                vm.backtestResult = res.data;
                vm.invest_term = vm.backtestResult.start_year;
                vm.month_yield_lists = vm.backtestResult['monthly_return'];
                vm.allocate_lists = vm.backtestResult['allocate_data'];                
                vm.balances_headers = vm.backtestResult['balances_header'];
                vm.balances = vm.backtestResult['balances'];
                vm.qs_month = vm.backtestResult['qs_month'];
                vm.qs_data = vm.backtestResult['qs_data'];
                vm.setQS({'qs_country': 'global', 'qs_month': vm.qs_month, 'qs_data': vm.qs_data});
                vm.loadTable = false;                
                
                vm.overviewShow = true;   
                var v_labels = [];
                var v_data = [];
                var v_dataset = {};
                var v_datasets = [];                
                var month_estimate_dict = vm.backtestResult['estimate_amount']; 
                for (var key in month_estimate_dict) {
                  v_labels.push(key);   
                  v_data.push(month_estimate_dict[key]);
                }
                // portfolio allocations pie chart
                vm.pie_datacollection = { labels: vm.backtestResult['pie_chart_labels'], datasets: vm.backtestResult['pie_chart_datasets'] };
                vm.pie_chartLoaded = true;
                vm.chart_hight = vm.backtestResult['pie_chart_labels'].length * 32;

                // assets line chart
                v_dataset = { data: v_data, label: 'Model Portfolio', borderColor: '#0D47A1', backgroundColor: '#BBDEFB', borderWidth: '4', 
                  fill: true, pointRadius: 0};
                v_datasets.push(v_dataset)
                vm.datacollection = { labels: v_labels, datasets: v_datasets};                
                vm.chartLoaded = true;

                // mdd chart data
                vm.month_mdd_lists = vm.backtestResult['drawdown_list']
                var v_mdd_datasets = [];                
                
                v_mdd_datasets.push({ label: 'MDD', data: vm.month_mdd_lists, borderColor: '#EF5350', backgroundColor: '#FFCDD2', fill: true, pointRadius: 0});
                vm.mdd_datacollection = { labels: v_labels, datasets: v_mdd_datasets};
                vm.mdd_chartLoaded = true;

                // year yield bar chart
                vm.bar_datacollection = { labels: vm.backtestResult['bar_chart_labels'], datasets: vm.backtestResult['bar_chart_datasets'] };
                vm.bar_chartLoaded = true; 
                })
                .catch(function (err) {
                  vm.loadTable = false;
                  vm.chartLoaded = false;
                });  
        },

        sleep(t){
          return new Promise(resolve=>setTimeout(resolve,t));
        },

 
        go_to_back() {        
            this.testShow = false;
            this.ruleShow = true;
        },

        getColor (value) {
            if (value < 0 ) return 'red';
            else return 'green';
        },    
        
        showComment(item) {
          this.helpDialogShow = true;
          if (item == 'cum_yield') {
            this.helpTitle = '누적수익율 (CAGR)'
            this.helpText = '누적수익율은 연복리 수익율로서 투자원금에 수익금을 계속 재투자 함으로서 수익율 최대화가 가능, 72법칙을 시용하면 원금의 2배가 되는 기간과 수익율을 확인할 수 있다. 예를 들면 연복리수익 14%일 경우 (72/14=5.1) 약 5.1년이 걸린다.'
          }
          else if (item == 'mdd') {
            this.helpTitle = '최대낙폭 (MDD)'
            this.helpText = 'MDD = (최저점/전고점) - 1 특정 투자 기간 중 겪을 수 있는 가장 큰 손실을 의미'
          }
          else if (item == 'sharpe') {
            this.helpTitle = '샤프지수 (Sharpe Ratio)'
            this.helpText = '샤프지수 = (연복리수익율 - 무위험이자율) / 변동성, 특정 주식 또는 포토폴리오의 리스크 조정수익으로 샤프지수가 높다는 것은 리스크가 적다는 뜻임. 무위험 이자율은 2022년 1월 현재 은행 정기예금 최대 이자율인 2%로 설정'
          }
          else if (item == 'yield_month') {
            this.helpTitle = '수익발생월'
            this.helpText = '총 테스트 기간 중 수익이 발생한 월의 비율'
          }
          
        },
        
    },
    
}
</script>

<style>
.etf-backtest-result{
   font-family: 'Source Sans Pro', 'Noto Sans KR script=latin rev=3';
   margin-top: 10px;
}
.red {
  color: red;
}
.stackSheet {
  position: relative;
}
.stackSpark {
  position: absolute;
  top: 0;
  left: 0;
}
</style>

